<template>
  <div v-if="themeDone" class="layout" :class="{'layout_full-height': isLoginPage || isRegisterPage}" id="app">

    <Icons :iconsContext="iconsContext"></Icons>
    <Layout v-if="useLayout" :type="layoutType" :banners="banners">
      <MobileAppBanner
        :scountImg="scountImg"
        :state="isBannerMobile"
        @hide="onCloseBanner"
        @action="installMobileModalOpen"
      />
      <div v-if="!isMobileApp" class="header-container" id="appHeaderContainer">
        <Header
          :logo="headerPropsLogo"
          logoAlt="Scount"
          :profile="{ avatarUrl: avatar ? avatar : headerAvatar, link: '/dashboard', name: firstName }"
          :menu="headerProps.menu"
          :menuHidden="headerProps.menuHidden"
          :mobileMenu="headerProps.mobileMenu"
          :state="isAuthenticated ? 'authorized' : 'unauthorized'"
          :notifications="[]"
          :coins="balance ? balance.amount : 0"
          :backText="backText"
          @open-notifications="onNotifications"
          @open-wallet="onWallet"
          @back="onBack"
        ></Header>
      </div>

      <router-view
        :style="{
          'min-height': `calc(${windowHeight}px - ${headerHeight}px - ${footerHeight}px)`,
          'height': 'auto',
        }"
      />

      <Footer
        v-if="!isMobileApp"
        id="appFooterContainer"
        copyright="ООО «Арена Плюс»"
        :links="footerLinks"
        :socials="footerSocials"
        :languages="[
          { id: 'ru', text: 'Русский', current: true },
        ]"
        :feedback="footerFeedback"
        @feedback="onFeedback"
      ></Footer>
    </Layout>

    <router-view v-if="!useLayout" />

    <Wallet
      :active="walletActive"
      :localizedText="{
        title: 'Мой кошелёк',
        buy: 'Что купить?',
      }"
      :coins="balance ? balance.amount : 0"
      :days="walletOperations"
      @close="walletActive = false"
      @buy="onBuy"
    ></Wallet>

    <Onboarding></Onboarding>
    <!-- :subtitle="`Получено ${ achievements.length } / ${ achievementsAll.length }`" -->

    <Modal
      :active="installMobileModal"
      name="installMobileModal"
      title="Установите мобильное приложение СКАУНТ"
      :subtitle="`Ваш код спортивной программы: <span>${ code ?code.code : '' }</span>`"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close="installMobileModal = false"
      :img="scountImg"
    >
      <div class="modal-qrcode">
        <div class="modal-qrcode__items">
          <a v-if="OS === 'unknown'" href="https://apps.apple.com/ru/app/%D1%81%D0%BA%D0%B0%D1%83%D0%BD%D1%82/id6504521298" class="modal-qrcode__item">
            <img class="modal-qrcode__item-img" :src="appstoreImg" alt="" />
          </a>
          <a v-if="OS === 'iOS'" href="https://apps.apple.com/ru/app/%D1%81%D0%BA%D0%B0%D1%83%D0%BD%D1%82/id6504521298" class="modal-qrcode__item">
            <img class="modal-qrcode__item-img" :src="appstoreImg" alt="" />
          </a>
          <a v-if="OS === 'unknown'"  href="https://www.rustore.ru/catalog/app/pro.scount.app" class="modal-qrcode__item">
            <img class="modal-qrcode__item-img" :src="rustoreImg" alt="" />
          </a>
          <a v-if="OS === 'Android'"  href="https://www.rustore.ru/catalog/app/pro.scount.app" class="modal-qrcode__item">
            <img class="modal-qrcode__item-img" :src="rustoreImg" alt="" />
          </a>
        </div>
        <ul class="modal-qrcode__list">
          <li class="modal-qrcode__list-item">
            <Icon name="steps"></Icon>
            <div class="modal-qrcode__list-body">
              <span>Подключите шагомер</span> и получайте баллы <img :src="coinImg"/> за каждую 1 000 шагов
            </div>
          </li>
          <li class="modal-qrcode__list-item">
            <Icon name="stopwatch"></Icon>
            <div class="modal-qrcode__list-body">
              <span>Используйте встроенный трекер</span> без необходимости подключать сторонние приложения и гаджеты
            </div>
          </li>
          <li class="modal-qrcode__list-item">
            <Icon name="bell"></Icon>
            <div class="modal-qrcode__list-body">
              <span>Получайте пуш-уведомления</span> и не пропускайте важные события и розыгрыши
            </div>
          </li>
        </ul>
      </div>
    </Modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { Base64 } from 'js-base64';
import Icons from '@rr-component-library/icons/src/main';
import Layout from '@sc-component-library/layout/src/main';
import Header from '@sc-component-library/header/src/main';
import Wallet from '@sc-component-library/wallet/src/main';
import Footer from '@sc-component-library/footer/src/main';
import Onboarding from '@/components/Onboarding/Onboarding.vue';
import { scrollToId } from '@/assets/js/helpers';
import Avatars from '@/assets/data/avatars';
import ScountImgMiddle from '@/assets/img/scount-middle.svg';
import QRCodeImg from '@/assets/img/qrcode.png';
import RustoreImg from '@/assets/img/rustore.jpg';
import AppstoreImg from '@/assets/img/appstore.jpg';
import GoogleplayImg from '@/assets/img/google-play.jpg';
import CoinImg from '@/assets/img/coin.svg';
import MobileAppBanner from '@/components/MobileAppBanner/MobileAppBanner.vue';

export default {
  name: 'App',
  components: {
    Icons,
    Layout,
    Header,
    Wallet,
    Footer,
    Onboarding,
    MobileAppBanner,
  },
  data() {
    return {
      iconsContext: require.context(
        '!svg-inline-loader?'
        + 'removeTags=true&removeSVGTagAttrs=true&removingTagAttrs=fill'
        + '!../src/assets/icons',
        false,
        /\w+\.svg$/i,
      ),
      walletOperations: [],
      walletActive: false,
      windowHeight: 0,
      headerHeight: 0,
      footerHeight: 0,
      avatar: '',
      firstName: '',
      stepImage: '',
      footerFeedback: null,
      scountImg: ScountImgMiddle,
      rustoreImg: RustoreImg,
      appstoreImg: AppstoreImg,
      googleplayImg: GoogleplayImg,
      qr: QRCodeImg,
      coinImg: CoinImg,
      installMobileModal: false,
      isBannerMobile: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeDone', 'options', 'resources', 'checkAppUrl']),
    ...mapGetters('user', ['profile', 'isAuthenticated', 'questionnaire', 'questionnaireStatus', 'isMobileApp', 'hasParticipantActiveDeviceSession', 'code']),
    ...mapGetters('wallet', ['balance', 'operations']),
    ...mapGetters('operations', ['balanceOperations']),
    ...mapGetters('content', ['rule']),
    isNotPage() {
      return this.$route.name !== 'Home' && this.$route.name !== 'Login' && this.$route.name !== 'Register' && this.$route.name !== 'RegisterComplete' && this.$route.name !== 'Auth' && this.$route.name !== 'AuthConfirm' && this.$route.name !== 'AuthSSO' && this.$route.name !== 'AuthComplete' && this.$route.name !== 'AuthSSOComplete' && this.$route.name !== 'Support';
    },
    isSpecialSettingsMp() {
      return this.options ? this.options.isSpecialSettingsMp === true : false;
    },
    OS() {
      const userAgent = navigator.userAgent || navigator.vendor || (window).opera;
      if (/android/i.test(userAgent)) {
        return 'Android';
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }
      return 'unknown';
    },
    isMobileOS() {
      return this.OS !== 'unknown';
    },
    setCookieFromMobileApp() {
      return this.$route.query.setCookieFromMobileApp === 'true';
    },
    marketingProgramCode() {
      return this.$route.query.code;
    },
    useLayout() {
      return !this.isLoginPage && !this.isRegisterPage && !this.isTeamPage && !this.isTeamCreatePage;
    },
    layoutType() {
      return !this.isInternalPage && this.$route.name !== 'Home' ? 'general' : 'banners';
    },
    headerProps() {
      return {
        menu: [
          {
            text: 'Задания',
            action: () => { this.onAction('tasks'); },
          },
          {
            text: 'Маркет',
            action: () => { this.onAction('market'); },
          },
          {
            text: 'Рейтинг',
            action: () => { this.onAction('rating'); },
          },
          {
            text: this.menuUnauthorizedReglamentName || 'Регламент',
            action: this.supportReglament,
            target: '_blank',
          },
        ],
        menuHidden: [
          {
            text: 'Школа ЗОЖ',
            action: this.menuUnauthorizedVK,
            target: '_blank',
            external: true,
          },
          {
            text: 'Кабинет участника',
            action: () => this.$router.push({ name: 'Dashboard' }).catch(() => {}),
          },
          {
            text: 'Поддержка',
            action: () => this.$router.push({ name: 'Support' }).catch(() => {}),
          },
        ],
        mobileMenu: [
          {
            text: 'Задания',
            action: () => { this.onAction('tasks'); },
          },
          {
            text: 'Маркет',
            action: () => { this.onAction('market'); },
          },
          {
            text: 'Рейтинг',
            action: () => { this.onAction('rating'); },
          },
          {
            text: this.menuUnauthorizedReglamentName || 'Регламент',
            action: this.supportReglament,
            target: '_blank',
          },
          {
            divider: true,
          },
          {
            text: 'Школа ЗОЖ',
            action: this.menuUnauthorizedVK,
            target: '_blank',
            external: true,
          },
          {
            text: 'Кабинет участника',
            action: () => this.$router.push({ name: 'Dashboard' }).catch(() => {}),
          },
          {
            text: 'Поддержка',
            action: () => this.$router.push({ name: 'Support' }).catch(() => {}),
          },
        ],
      };
    },
    banners() {
      return this.resources ? this.resources.banners || null : null;
    },
    headerPropsLogo() {
      return this.resources ? this.resources.logo || '' : '';
    },
    headerAvatar() {
      return this.resources ? this.resources.headerAvatar || '' : '';
    },
    footerLinks() {
      if (!this.resources) {
        return [];
      }
      const rule = {
        text: 'Правила',
        href: this.supportReglament,
      };
      const updatedFooterLinks = [...this.resources.footerLinks];
      updatedFooterLinks.splice(1, 0, rule);
      return updatedFooterLinks;
    },
    footerSocials() {
      return this.resources ? this.resources.footerSocials || [] : [];
    },
    supportVK() {
      return this.resources ? this.resources.supportVK || '#' : '#';
    },
    menuUnauthorizedVK() {
      return this.resources ? this.resources.menuUnauthorizedVK || '#' : '#';
    },
    menuUnauthorizedReglamentName() {
      return this.resources ? this.resources.menuUnauthorizedReglamentName || '' : '';
    },
    supportReglament() {
      return this.rule ? this.rule.url : '#';
    },
    menuAboutTitleMobile() {
      return this.resources ? this.resources.menuAboutTitleMobile || '' : '';
    },
    isTeamsEnabled() {
      return this.options ? this.options.isTeamsEnabled === true : false;
    },
    isTeamStepRequired() {
      return this.options ? this.options.isTeamStepRequired === true : false;
    },
    isFeedbackEnabled() {
      return this.options ? this.options.isFeedbackEnabled === true : false;
    },
    isTeamSet() {
      return this.profile ? this.profile.team !== undefined && this.profile.team.teamId !== undefined : false;
    },
    isLoginPage() {
      return this.$route.name === 'Auth' || this.$route.name === 'AuthConfirm' || this.$route.name === 'AuthSSO';
    },
    isRegisterPage() {
      return this.$route.name === 'Register' || this.$route.name === 'RegisterComplete';
    },
    isTeamPage() {
      return this.$route.name === 'Team';
    },
    isTeamCreatePage() {
      return this.$route.name === 'TeamCreate';
    },
    isInternalPage() {
      return !['Home', 'Auth', 'AuthConfirm', 'AuthSSO', 'Register', 'RegisterComplete', 'Team', 'TeamCreate'].includes(this.$route.name);
    },
    routeName() {
      return this.$route.name;
    },
    backText() {
      const names = {
        Tasks: 'Задания',
        TasksAvailable: 'Задания',
        TasksInProgress: 'Задания',
        TasksCompleted: 'Задания',
        Nominations: 'Рейтинг',
        NominationsInProgress: 'Рейтинг',
        NominationsAvailable: 'Рейтинг',
        NominationsCompleted: 'Рейтинг',
        Activities: 'Активность',
        Settings: 'Мой аккаунт',
        SettingsProfile: 'Мой аккаунт',
        SettingsTrainings: 'Мой аккаунт',
        SettingsTrackers: 'Мой аккаунт',
        SettingsHistory: 'Мой аккаунт',
        Market: 'Маркет',
        RewardDetails: 'Маркет',
        Community: 'Школа ЗОЖ',
        About: this.menuAboutTitleMobile ? this.menuAboutTitleMobile : 'О чемпионате',
        Support: 'Поддержка',
        MyTeam: 'Команда',
        Participants: 'Участники',
        Calendar: 'Календарь',
        Club: 'Клуб',
      };
      return names[this.$route.name] || '';
    },
    isStepImage() {
      return '<div class="step-image"></div>';
    },
  },
  watch: {
    profile(val) {
      if (val) {
        this.avatar = this.getAvatarImage(this.profile ? this.profile.avatar : null);
        this.firstName = this.profile ? this.profile.firstName : '';
        this.getDeviceSession();
        this.getCodeMp();
        setTimeout(() => { this.processIsTeamRequired(); }, 250);
      }
    },
    themeDone(val) {
      if (val) {
        this.checkForMobile();
        this.saveTeamInvitation();
        this.processMenuItems();
        this.setBannerMobile();
        setTimeout(() => { this.processIsTeamRequired(); }, 250);
        setTimeout(() => { this.initUserback(); }, 1000);
      }
    },
    routeName() {
      setTimeout(() => { this.processIsTeamRequired(); }, 250);
    },
  },
  methods: {
    ...mapActions('settings', ['loadSettings']),
    ...mapActions('operations', ['getBalanceOperations']),
    ...mapActions('user', ['getQuestionnaire', 'setIsMobileApp', 'hasDeviceSession', 'getCode']),
    ...mapActions('content', ['getRule']),

    detectOS() {
      if (this.$root.isDesktop) {
        this.desktop = true;
      } else {
        const { platform } = window.navigator;
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        if (iosPlatforms.indexOf(platform) !== -1) {
          this.iOS = true;
          return;
        }
        this.android = true;
      }
    },

    getCodeMp() {
      if (this.profile) {
        this.getCode(this.profile.marketingProgramId);
      }
    },
    setBannerMobile() {
      if (this.isSpecialSettingsMp && this.isNotPage && this.hasParticipantActiveDeviceSession === false && !this.isMobileApp) {
        this.isBannerMobile = true;
      }
    },
    onCloseBanner() {
      this.isBannerMobile = false;
      // sessionStorage.setItem('bannerMobile1', 'false');
    },
    installMobileModalOpen() {
      this.installMobileModal = true;
    },
    changeLang(id) {
      localStorage.setItem('language', id);
      window.location.reload();
    },
    newLink() {
      return this.resources.footerLinks.push(this.rule);
    },
    onAction(page) {
      if (this.$route.name !== 'Home') {
        window.location.href = `${window.location.origin}/#${page}`;
        return;
      }
      scrollToId(page, true);
    },
    onNotifications() {
      console.log('notifications');
    },
    onWallet() {
      this.getBalanceOperations({ offset: 0, count: 50 })
        .then(() => {
          this.walletOperations = this.getMapppedOperations(this.balanceOperations.values);
          this.walletActive = true;
        });
    },
    onBuy() {
      this.walletActive = false;
      this.$router.push({ name: 'Market' }).catch(() => {});
    },
    onBack() {
      this.$router.push({ name: 'Dashboard' }).catch(() => {});
    },
    onResize() {
      this.$root.isMobile = window.innerWidth < 768;
      this.$root.isTablet = window.innerWidth >= 768 && window.innerWidth < 1200;
      this.$root.isDesktop = window.innerWidth >= 1200;
      const header = document.getElementById('appHeaderContainer');
      const footer = document.getElementById('appFooterContainer');
      this.windowHeight = window.innerHeight;
      const headerHeight = header ? header.offsetHeight || 0 : 0;
      const footerHeight = footer ? footer.offsetHeight || 0 : 0;
      if (headerHeight > 0) this.headerHeight = headerHeight;
      if (footerHeight > 0) this.footerHeight = footerHeight;
    },
    onFeedback() {
      if (!window.Userback) return;
      setTimeout(() => { window.Userback.open(); }, 250);
    },
    getMapppedOperations(operations) {
      if (!operations) return [];
      const days = [];
      const last = { date: '', index: 0 };
      for (let i = 0; i < operations.length; i += 1) {
        const value = operations[i];
        const day = { date: '', items: [] };
        const date = dayjs.utc(value.operationDate);
        if (date.isValid()) {
          day.date = date.format('D MMMM');
        } else {
          continue;
        }
        const item = this.getMappedItem(value);
        if (i === 0) {
          last.date = date.format('YYYY-MM-DD');
          last.index = 0;
          day.items = [item];
          days.push(day);
          continue;
        }
        if (last.date === date.format('YYYY-MM-DD')) {
          days[last.index].items.push(item);
          continue;
        }
        day.items = [item];
        days.push(day);
        last.date = date.format('YYYY-MM-DD');
        last.index = days.length - 1;
      }
      return days;
    },
    pluralizeSteps(count) {
      if (count % 10 === 1 && count % 100 !== 11) {
        return `<b>${count}</b> шаг`;
      } if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
        return `<b>${count}</b> шага`;
      }
      return `<b>${count}</b> шагов`;
    },
    getMappedItem(value) {
      const item = {};
      if (value.attributes.OperationSource === 'Registration') {
        item.img = this.avatar;
        item.title = 'Добро пожаловать';
        item.type = 'Приветственный бонус';
        item.coins = Math.round(value.attributes.Amount);
        return item;
      }
      if (value.attributes.OperationSource === 'ExtendedQuestionnaire') {
        item.img = this.avatar;
        item.title = 'Анкета участника';
        item.type = 'Награда за ответы';
        item.coins = Math.round(value.attributes.Amount);
        return item;
      }
      if (value.attributes.OperationSource === 'Exercise') {
        item.img = value.attributes.ExerciseMobileImageUrl || '';
        item.title = value.attributes.ExerciseTitle;
        item.type = 'Награда за задание';
        item.coins = Math.round(value.attributes.Amount);
        item.link = `/tasks/completed#!showTask=${value.sourceId}&peId=${value.attributes.OperationSourceId}`;
        return item;
      }
      if (value.attributes.OperationSource === 'MarketProduct') {
        item.img = value.attributes.ProductMobileImageUrl;
        item.title = value.attributes.ProductName;
        item.type = 'Покупка за баллы';
        item.coins = -Math.round(value.attributes.Amount);
        item.link = '/my-settings/purchase-history';
        return item;
      }
      if (value.attributes.OperationSource === 'StepsInDay') {
        item.icon = 'steps';
        item.title = this.pluralizeSteps(Math.round(value.attributes.StepsCount));
        item.type = 'Ежедневная награда';
        item.coins = Math.round(value.attributes.Amount);
        return item;
      }
      item.img = '';
      item.title = value.attributes.OperationSource;
      item.type = value.attributes.OperationType;
      item.coins = Math.round(value.attributes.Amount);
      return item;
    },
    getAvatarImage(avatar) {
      if (!avatar) return '';
      if (!avatar.type) return '';
      if (avatar.type === 'custom') {
        return avatar.url || '';
      }
      const found = Avatars.filter((i) => i.id === avatar.type);
      if (found.length === 0) return '';
      return found[0].img || '';
    },
    processMenuItems() {
      for (let i = 0; i < this.headerProps.menu.length; i += 1) {
        if (typeof this.headerProps.menu[i].action === 'string') {
          this.headerProps.menu[i].action = this.replaceVariables(this.headerProps.menu[i].action);
        }
      }
      for (let i = 0; i < this.headerProps.menuHidden.length; i += 1) {
        if (typeof this.headerProps.menuHidden[i].action === 'string') {
          this.headerProps.menuHidden[i].action = this.replaceVariables(this.headerProps.menuHidden[i].action);
        }
      }
      for (let i = 0; i < this.headerProps.mobileMenu.length; i += 1) {
        if (typeof this.headerProps.mobileMenu[i].action === 'string') {
          this.headerProps.mobileMenu[i].action = this.replaceVariables(this.headerProps.mobileMenu[i].action);
        }
      }
    },
    replaceVariables(text) {
      if (!text) return '';
      const vars = [
        { id: 'supportVK', value: this.supportVK },
        { id: 'menuUnauthorizedVK', value: this.menuUnauthorizedVK },
        { id: 'supportReglament', value: this.supportReglament },
      ];
      let result = text;
      for (let i = 0; i < vars.length; i += 1) {
        result = result.replaceAll(`{{${vars[i].id}}}`, vars[i].value);
      }
      return result;
    },
    processIsTeamRequired() {
      if (!this.isInternalPage) return;
      if (!this.profile || Object.keys(this.profile).length === 0 || !this.themeDone) return;
      if (!this.isTeamsEnabled || !this.isTeamStepRequired) return;
      if (this.questionnaire && Object.keys(this.questionnaire).length > 0) {
        const IsUnionMember = this.getAnswerByKey('IsUnionMember');
        if ((IsUnionMember === 'true' || IsUnionMember === '') && !this.isTeamSet) {
          this.$router.push({ name: 'Team' }).catch(() => {});
        }
      } else {
        this.getQuestionnaire()
          .then(() => {
            if (this.questionnaireStatus === 200) {
              const IsUnionMember = this.getAnswerByKey('IsUnionMember');
              if ((IsUnionMember === 'true' || IsUnionMember === '') && !this.isTeamSet) {
                this.$router.push({ name: 'Team' }).catch(() => {});
              }
            }
          });
      }
    },
    getAnswerByKey(key) {
      if (!this.questionnaire.answers || !key) return '';
      const found = this.questionnaire.answers.filter((i) => i.key === key);
      if (found.length === 0) return '';
      return found[0].value;
    },
    saveTeamInvitation() {
      const { t } = this.$route.query;
      if (t) {
        if (Base64.isValid(t)) {
          sessionStorage.setItem('invitationTeamId', Base64.decode(t));
        }
      }
    },
    saveParams() {
      const { ref } = this.$route.query;
      if (ref) {
        if (Base64.isValid(ref)) {
          sessionStorage.setItem('ref', Base64.decode(ref));
        }
      }
    },
    checkForMobile() {
      const isMobileApp = Cookies.get('fromMobileApp') === 'true';
      this.setIsMobileApp({ value: isMobileApp });
      if (!this.checkAppUrl || !this.isMobileOS || this.isMobileApp) return;
      const appChecked = sessionStorage.getItem('appCheckedForMobile') === 'true';
      if (appChecked) return;
      const { loginReturnUrl } = this.$route.query;
      let backURL = '';
      if (loginReturnUrl) {
        backURL = this.removeProtoFromURL(loginReturnUrl);
      } else {
        backURL = window.location.href;
        backURL = backURL.slice(window.location.protocol.length + 2);
      }
      const secureProto = 'https://';
      const hasProtoInCheckApp = this.checkAppUrl.indexOf(secureProto) === 0;
      window.location.href = `${!hasProtoInCheckApp ? secureProto : ''}${this.checkAppUrl}/url=${encodeURI(backURL)}`;
    },
    removeProtoFromURL(url) {
      const index = url.indexOf('//');
      if (index !== -1 && index + 2 <= url.length - 1) return url.slice(url.indexOf('//') + 2);
      return url;
    },
    checkForCode() {
      if (this.marketingProgramCode) {
        sessionStorage.setItem('marketingProgramCode', this.marketingProgramCode);
      }
    },
    initUserback() {
      if (!this.isFeedbackEnabled) return;

      const id = 'userback-sdk';
      const created = document.getElementById(id);
      if (created !== null) {
        return;
      }
      const s = document.createElement('script');
      s.onload = () => {
        window.Userback.init('A-8JKkhdf7f0dN5GxQG49YWEUMswJQ3S8bwtEzKNSNcXvaEH4r15', {
          widget_settings: {
            autohide: true,
            language: 'ru',
          },
        });
        this.footerFeedback = {
          icon: 'feedback',
          text: 'Отзыв о сайте',
        };
      };
      s.id = id;
      s.src = 'https://static.userback.io/widget/v1.js';
      const parentNode = document.head || document.body;
      parentNode.appendChild(s);
    },
    getDeviceSession() {
      if (this.profile && this.profile.participantId) {
        this.hasDeviceSession({ participantId: this.profile.participantId })
          .then(() => {
            this.setBannerMobile();
          });
      }
    },
    goPage(name, path) {
      if (!name || !path) return;
      this.$router.push({ path: `/goPage?name=${name}&path=${path}` }).catch(() => {});
    },
    openInExternal(url, returnPath = '') {
      if (!url) return;
      this.$router.push({ path: `/openInExternal?url=${url}&returnPath=${returnPath}` }).catch(() => {});
    },
  },
  created() {
    this.$root.isMobile = false;
    this.$root.isTablet = false;
    this.$root.isDesktop = false;
    Vue.prototype.$changeLang = this.changeLang;
    this.$root.$goPage = this.goPage;
    this.$root.$openInExternal = this.openInExternal;
  },
  mounted() {
    if (this.setCookieFromMobileApp) {
      Cookies.set('fromMobileApp', 'true', { expires: 365 });
      if (this.$route.query.token) Cookies.set('token', this.$route.query.token, { expires: 365 });
      const query = { ...this.$route.query };
      delete query.setCookieFromMobileApp;
      delete query.token;
      this.$router.replace({ query });
    }
    this.getRule();
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.loadSettings();
    this.checkForCode();
    if (this.themeDone) {
      this.checkForMobile();
      this.saveTeamInvitation();
      this.saveParams();
      this.processMenuItems();
      this.setBannerMobile();
      setTimeout(() => { this.processIsTeamRequired(); }, 250);
      setTimeout(() => { this.initUserback(); }, 1000);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss" scoped>
  .layout {
    height: 100%;

    &_full-height {
      height: 100%;
    }

    ::v-deep .rr-modal .rr-modal__subtitle a,
    ::v-deep .rr-modal .rr-modal__subtitle a:link,
    ::v-deep .rr-modal .rr-modal__subtitle a:hover,
    ::v-deep .rr-modal .rr-modal__subtitle a:active,
    ::v-deep .rr-modal .rr-modal__subtitle a:visited {
      text-decoration: none;
      color: var(--color-primary, #FF8233);
    }
    ::v-deep .rr-modal .rr-modal__subtitle span {
      color: var(--color-primary, #FF8233);
      font-weight: 600;
    }
    ::v-deep .rr-modal__window {
      border-radius: 8px;
    }
  }

  .scount-layout {
    height: 100%;

    ::v-deep .scount-layout__container,
    ::v-deep .scount-layout__wrapper {
      height: 100%;
    }

    ::v-deep .header-container {
      position: relative;
      height: 56px;

      @media (min-width: breakpoint(md)) {
        height: 64px;
      }

      @media (min-width: breakpoint(xl)) {
        height: 72px;
      }
    }

    ::v-deep .header__notification-button {
      display: none;
    }
  }
  ::v-deep .entry__text {
      min-width: 0;
    }
  ::v-deep .entry__coins{
    max-width: 100%;
  }
  .modal-qrcode {
    &__img {
      width: 169px;
      height: 169px;
      margin-bottom: 25px;
    }
    &__items {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
    &__list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      list-style: none;
      padding-top: 50px;
      padding-bottom: 20px;
      &-item {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        span {
          font-weight: 600;
        }
        svg {
          min-width: 32px;
          width: 32px;
          height: 32px;
          color: var(--color-primary, #FF8233);
        }
      }
      &-body {
        text-align: left;
        padding-left: 16px;
      }
    }
  }
</style>
