import api from './api';

const qs = require('qs');

export default {
  buyPromotion({ promotionId, purchaseId, promocodesNumber = 1 }) {
    return api.put(`/marketplace/api/v1/promotions/${promotionId}/buy`, {
      purchaseId,
      promocodesNumber,
    });
  },
  getPromocodes({ purchaseId }) {
    return api.get('/marketplace/api/v1/promocodes', {
      params: {
        purchaseId,
      },
    });
  },
  getPromotions({
    offset, count, sort, filters,
  }) {
    return api.get('/marketplace/api/v1/promotions', {
      params: {
        offset,
        count,
        ...sort,
        ...filters,
      },
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    });
  },
  getPromotion({ promotionId }) {
    return api.get(`/marketplace/api/v1/promotions/${promotionId}`);
  },
  getPriceRange() {
    return api.get('/marketplace/api/v1/promotions/price');
  },
  getPromotionTypes() {
    return api.get('/marketplace/api/v1/promotions/types');
  },
  getPartners() {
    return api.get('/marketplace/api/v1/partners');
  },
  getCategories({ offset, count, sort }) {
    return api.get('/marketplace/api/v1/categories', {
      params: {
        offset,
        count,
        ...sort,
      },
    });
  },
};
